<template>
  <moe-page title="用户日志">
    <!-- 筛选信息 -->
    <moe-inquire @search="userLogSearch">
      <el-form-item label="手机号">
        <el-input v-model.trim="userLogParams.phone" placeholder="输入手机号" maxlength="100" clearable />
      </el-form-item>
    </moe-inquire>

    <!-- 日志信息 -->
    <moe-table ref="userLogTable" url="/log/log" :params="userLogParams">
      <el-table-column prop="userId" label="用户ID" min-width="60" />
      <el-table-column prop="phone" label="手机号码" min-width="130" />
      <el-table-column prop="phoneId" label="手机ID" min-width="130" />
      <el-table-column prop="platform" label="平台" min-width="100" />
      <el-table-column prop="version" label="app版本" min-width="100" />
      <el-table-column prop="file" label="文件路径" min-width="130" />
      <el-table-column prop="updateTime" label="更新时间" min-width="130">
        <template slot-scope="{ row }">{{ $moe_time.getTimeStamp(row.updateTime, 'YYYY-MM-DD') }}</template>
      </el-table-column>
      <el-table-column label="操作" width="300" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-bottom" @click="downloadTemplate(row.file)">下载日志</el-button>
        </div>
      </el-table-column>
    </moe-table>

  </moe-page>
</template>

<script>
export default {
  name: 'userLog',
  data() {
    //日志筛选信息
    let userLogParams = {
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
    };

    return {
      userLogParams, //日志筛选信息
      fileUrl:''
    };
  },
  methods: {
    handleImg(baseUrl) {
      this.$moe_api.LOGIN_API.getUserFileURL({ baseUrl }).then((data) => {
        console.log(data)
      })
      this.$nextTick(() => {

      })
    },
    /**
     * 日志搜索
     **/
    userLogSearch(isSearch) {
      if (!isSearch) {
        this.userLogParams = {
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
        };

        this.dateTime = [];
      }

      //刷新日志表格
      this.$refs.userLogTable.searchData();
    },

    /* 下载日志 */
    async downloadTemplate(url) {
      window.open(
        url,
        '_black'
      );
    }
  }
};
</script>